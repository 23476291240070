import { handleUrlParamEncrypt } from "./react-ec-utils";

export const getCheckInConfirmationURL = (
  merchantType: string,
  merchantSlug: string,
  reservationId: string
) => {
  return (
    "/" + merchantType + "/" + merchantSlug + "/reservation/" + reservationId
  );
};

export const getMerchantHomeURL = (
  merchantType: string,
  merchantSlug: string
) => {
  return "/" + merchantType + "/" + merchantSlug;
};

export const getMerchantMenuURL = (merchantSlug: string, menuType: string) => {
  return "/restaurant/" + merchantSlug + "/menu/" + menuType;
};

export const getSalonMenuURL = (merchantSlug: string, menuType: string) => {
  return "/salon/" + merchantSlug + "/menu/" + menuType;
};

export const getRestaurantCheckInURL = (merchantSlug: string) => {
  return "/restaurant/" + merchantSlug + "/checkIn";
};

export const getRestaurantCheckInWelcomeURL = (merchantSlug: string) => {
  return "/restaurant/" + merchantSlug + "/checkIn/welcome";
};

export const getRestaurantCheckInURLWithTableId = (
  merchantSlug: string,
  tableId: string
) => {
  return "/restaurant/" + merchantSlug + "/checkIn/table/" + tableId;
};

export const getRestaurantCheckInTableId = (
  merchantSlug: string,
  tableId: string
) => {
  return "/restaurant/" + merchantSlug + "/tableside/checkIn/" + tableId;
};

export const getRestaurantCheckInURLWithTableIdForm = (
  merchantSlug: string,
  tableId: string
) => {
  return (
    "/restaurant/" + merchantSlug + "/checkIn/table/" + tableId + "/details"
  );
};

export const getContactlessDineinLandingPage = (
  merchantType: string,
  merchantSlug: string,
  tableId: string,
  menuType: string
) => {
  return (
    "/" +
    merchantType +
    "/" +
    merchantSlug +
    "/table/" +
    tableId +
    "/menu/" +
    menuType +
    "/contactlessdinein/landing"
  );
};

export const getContactlessDineinRatingURL = (
  merchantType: string,
  merchantSlug: string,
  tableId: string,
  orderId: string
) => {
  return (
    "/" +
    merchantType +
    "/" +
    merchantSlug +
    "/table/" +
    tableId +
    "/order/" +
    orderId +
    "/rating"
  );
};

export const getRestaurantMenuURLWithTableID = (
  merchantType: string,
  merchantSlug: string,
  tableId: string,
  menuType: string
) => {
  return (
    "/" +
    merchantType +
    "/" +
    merchantSlug +
    "/table/" +
    tableId +
    "/menu/" +
    menuType
  );
};

export const getRestaurantOrderedMenuURLWithTableID = (
  merchantType: string,
  merchantSlug: string,
  tableId: string,
  orderId: string,
  menuType: string
) => {
  return (
    "/" +
    merchantType +
    "/" +
    merchantSlug +
    "/table/" +
    tableId +
    "/order/" +
    orderId +
    "/menu/" +
    menuType
  );
};

export const getRestaurantMenuCustomisationURL = (
  merchantSlug: string,
  menuType: string,
  itemId: string
) => {
  return (
    "/restaurant/" + merchantSlug + "/menu/" + menuType + "/customize/" + itemId
  );
};

export const getRestaurantMenuPreviewItemURL = (
  merchantSlug: string,
  menuType: string,
  itemId: string
) => {
  return (
    "/restaurant/" + merchantSlug + "/menu/" + menuType + "/preview/" + itemId
  );
};

export const getRestaurantMenuCustomisationDineInURL = (
  merchantSlug: string,
  tableId: string,
  menuType: string,
  itemId: string
) => {
  return (
    "/restaurant/" +
    merchantSlug +
    "/table/" +
    tableId +
    "/menu/" +
    menuType +
    "/customize/" +
    itemId
  );
};

export const getPlaceOrderURL = (
  merchantType: string,
  merchantSlug: string,
  menuType: string
) => {
  return (
    "/" +
    merchantType +
    "/" +
    merchantSlug +
    "/menu/" +
    menuType +
    "/placeOrder"
  );
};

export const getPlaceOrderDesktopURL = (
  merchanType: string,
  merchantSlug: string
) => {
  return "/" + merchanType + "/" + merchantSlug + "/placeOrder";
};

export const getPlaceOrderPaymentURL = (
  merchantSlug: string,
  menuType: string
) => {
  return (
    "/restaurant/" + merchantSlug + "/menu/" + menuType + "/placeOrder/payment"
  );
};

export const getOrderTrackingURL = (merchantSlug: string, orderId: string) => {
  const encOrderId = handleUrlParamEncrypt(orderId)
  return "/restaurant/" + merchantSlug + "/order/" + encOrderId;
};

export const getContactlessDineInOrderStatusURL = (
  merchantType: string,
  merchantSlug: string,
  tableId: string,
  orderId: string
) => {
  return (
    "/restaurant/" + merchantSlug + "/table/" + tableId + "/order/" + orderId
  );
};

export const getContactlessDineInOfferURL = (
  merchantType: string,
  merchantSlug: string,
  tableId: string,
  orderId: string
) => {
  return (
    "/restaurant/" +
    merchantSlug +
    "/table/" +
    tableId +
    "/order/" +
    orderId +
    "/offer"
  );
};

export const getOrderSummaryURL = (
  merchantType: string,
  merchantSlug: string,
  orderId: string
) => {
  const encOrderId = handleUrlParamEncrypt(orderId)
  return (
    "/" +
    merchantType +
    "/" +
    merchantSlug +
    "/order/" +
    encOrderId +
    "/orderSummary"
  );
};

export const getPaymentStatusURL = (
  merchantType: string,
  merchantSlug: string,
  orderId: string
) => {
  return (
    "/" +
    merchantType +
    "/" +
    merchantSlug +
    "/order/" +
    orderId +
    "/payment/status"
  );
};

export const getContactlessDineinPaymentStatusURL = (
  merchantType: string,
  merchantSlug: string,
  tableId: string,
  orderId: string
) => {
  return (
    "/" +
    merchantType +
    "/" +
    merchantSlug +
    "/table/" +
    tableId +
    "/order/" +
    orderId +
    "/payment/status"
  );
};

export const getPaymentOptionURL = (
  merchantType: string,
  merchantSlug: string,
  menuType: string
) => {
  return (
    "/" +
    merchantType +
    "/" +
    merchantSlug +
    "/menu/" +
    menuType +
    "/placeOrder/payment"
  );
};

export const getPaymentOptionURLDineIn = (
  merchantType: string,
  merchantSlug: string,
  menuType: string,
  tableId: string,
  orderId: string
) => {
  return (
    "/" +
    merchantType +
    "/" +
    merchantSlug +
    "/menu/" +
    menuType +
    "/table/" +
    tableId +
    "/order/" +
    orderId +
    "/placeOrder/payment"
  );
};

export const getDineInPlaceOrderURL = (
  merchantSlug: string,
  menuType: string,
  tableId: string
) => {
  return (
    "/restaurant/" +
    merchantSlug +
    "/menu/" +
    menuType +
    "/table/" +
    tableId +
    "/placeOrder"
  );
};

export const getDineInOrderedPlaceOrderURL = (
  merchantSlug: string,
  menuType: string,
  tableId: string,
  orderId: string
) => {
  return (
    "/restaurant/" +
    merchantSlug +
    "/menu/" +
    menuType +
    "/table/" +
    tableId +
    "/order/" +
    orderId +
    "/placeOrder"
  );
};

export const getMerchantCancellationPolicyPageURL = (merchantSlug: string) => {
  return "/merchant/" + merchantSlug + "/policy/cancellation";
};

export const getSelectedLocationHomeURL = (lat: number, lng: number) => {
  return "/restaurants/location/" + lat + "/" + lng;
};

export const getGoogleMapUrl = (lat: number, lng: number) => {
  return "http://www.google.com/maps/place/" + lat + "," + lng;
};
