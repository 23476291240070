import { produce } from "immer";
import { RestaurantState } from "./restaurantModels";
import { restaurantActions } from "./restaurantAction";
import {
  RESTAURANT_DETAIL_REQUEST,
  RESTAURANT_DETAIL_SUCCESS,
  RESTAURANT_DETAIL_FAILURE,
  RESTAURANT_MENU_DETAIL_REQUEST,
  RESTAURANT_MENU_DETAIL_SUCCESS,
  RESTAURANT_MENU_DETAIL_FAILURE,
  FEEDBACK_DETAIL_REQUEST,
  FEEDBACK_DETAIL_SUCCESS,
  FEEDBACK_DETAIL_FAILURE,
  SUBMIT_FEEDBACK_DETAIL_REQUEST,
  SUBMIT_FEEDBACK_DETAIL_SUCCESS,
  SUBMIT_FEEDBACK_DETAIL_FAILURE,
  STORE_MENU_ITEM,
  RESTAURANTS_SUCCESS,
  RESTAURANTS_REQUEST,
  RESTAURANTS_FAILURE,
  STORE_SELECTED_LOCATION,
  CLEAR_SELECTED_LOCATION,
  REFRESH_MENU_DETAIL,
  SERVING_RESTAURANTS_SUCCESS,
  RESTAURANT_DETAIL_LOCATION_ID_REQUEST,
  RESTAURANT_DETAIL_LOCATION_ID_SUCCESS,
  RESTAURANT_DETAIL_LOCATION_ID_CLEAR,
  ACTIVE_ORDER_RESTAURANT_REQUEST,
  ACTIVE_ORDER_RESTAURANT_SUCCESS,
  ACTIVE_ORDER_RESTAURANT_CLEAR,
  GET_MASTER_HOME_PAGE_DETAILS,
  GET_MASTER_HOME_PAGE_DETAILS_SUCCESS,
  GET_MASTER_HOME_PAGE_DETAILS_ERROR,
  CLEAR_RESTAURANT_DETAIL,
  CLEAR_FEEDBACK_DATA,
  GET_RESTAURANT_TABLE_DETAILS,
  GET_RESTAURANT_TABLE_DETAILS_FAILED,
  GET_RESTAURANT_TABLE_DETAILS_SUCCESS,
  RESTAURANT_ORDER_TYPE_SWITCH_SUCCESS,
  UPDATE_CD_GUEST_COUNT
} from "./restaurantContants";

let restaurantData = {
  id: "",
  branchName: "",
  address: "",
  open: 1,
  city: "",
  country: "",
  delivery: 0,
  branch: [],
  media: [],
  rating: 0,
  phoneNumber: "",
  cost: "", // 200-2
  digitalMenu: 0,
  highChair: 0,
  dining: 0,
  takeAway: 0,
  onlineCheckin: 0,
  onlineOrder: 0,
  parking: [],
  cards: [],
  pref: [],
  tableSection: [],
  safetyMeasures: [],
  workingHours: [],
  facilities: [],
  cuisine: [],
  diets: [],
  itemTypes: [],
  maxCheckinWaitThreshold: 0,
  maxOnlineCheckin: 0,
  redirectUrl: "",
  orderTypes: [],
  defaultPickUpETA: 0,
  paymentProvider: null,
  deliveryKMRadius: null,
  locationDeliveryProviders: null,
  // Scheduled delivery
  scheduledDelivery: 0,
  scheduledDeliveryCutOffTime: "",
  scheduledDeliveryDurationInDays: "",
  locationSlug: "",
  disabledServices: null,
  cancellationPolicy: null,
  deliverySettings: null,
  platformConfiguration: null,
  aboutUs: null,
  merchantSlug: "",
  pickupCutoff: null,
};

const initialRestaurantState: RestaurantState = {
  // Location Based Restaurants
  currentLocation: null,
  currentLocationRestaurants: [],
  currentServingLocations: [],
  currentLocationRestaurantsLoading: false,
  restaurantDefaultTax: null,

  // Restaurant Details
  currentRestaurantDetail: restaurantData,
  currentRestaurantSlug: "",
  currentMerchantType: "",
  currentMenuType: "",
  restaurantDetailLoading: false,
  restaurantDetailsSuccess: false,
  restaurantDetailFailMessage: "",
  restaurantOrderTypeId: "", // ID for PickUp / Delivery / ..
  restaurantCurrentMenuType: "", // Pickup / Delivery
  restaurantMenuDetails: [],
  restaurantMenuCategories: [],
  restaurantSectionTypes: [],
  restaurantOrderTax: [],
  restaurantMenuDetailLoading: false,
  restaurantMenuDetailFailMessage: "",
  refreshMenuDetails: false,

  //Feedback state
  FeedbackQuestions: [],
  feedbackDetailLoading: false,
  feedbackDetailFailMessage: "",
  //Submitfeedback state
  SubmitFeedbackDetails: [],
  submitFeedbackDetailLoading: false,
  submitFeedbackDetailFailMessage: "",
  feedbackSubmitted: false,

  //Active Order Restaurant Details
  activeOrderRestaurantDetails: {
    id: "",
    branchName: "",
    address: "",
    open: 1,
    city: "",
    country: "",
    delivery: 0,
    branch: [],
    media: [],
    rating: 0,
    phoneNumber: "",
    cost: "", // 200-2
    digitalMenu: 0,
    highChair: 0,
    dining: 0,
    takeAway: 0,
    onlineCheckin: 0,
    onlineOrder: 0,
    parking: [],
    cards: [],
    pref: [],
    tableSection: [],
    safetyMeasures: [],
    workingHours: [],
    facilities: [],
    cuisine: [],
    diets: [],
    itemTypes: [],
    maxCheckinWaitThreshold: 0,
    maxOnlineCheckin: 0,
    redirectUrl: "",
    orderTypes: [],
    defaultPickUpETA: 0,
    paymentProvider: null,
    deliveryKMRadius: null,
    locationDeliveryProviders: null,
    // Scheduled delivery
    scheduledDelivery: 0,
    scheduledDeliveryCutOffTime: "",
    scheduledDeliveryDurationInDays: "",
    locationSlug: "",
    disabledServices: null,
    cancellationPolicy: null,
    deliverySettings: null,
    platformConfiguration: null,
    aboutUs: null,
    merchantSlug: "",
    pickupCutoff: null,
  },
  activeOrderRestaurantLoading: false,
  masterRestaurantHomePageDetails: {
    id: "",
    branchName: "",
    address: "",
    open: 1,
    city: "",
    country: "",
    delivery: 0,
    branch: [],
    media: [],
    rating: 0,
    phoneNumber: "",
    cost: "", // 200-2
    digitalMenu: 0,
    highChair: 0,
    dining: 0,
    takeAway: 0,
    onlineCheckin: 0,
    onlineOrder: 0,
    parking: [],
    cards: [],
    pref: [],
    tableSection: [],
    safetyMeasures: [],
    workingHours: [],
    facilities: [],
    cuisine: [],
    diets: [],
    itemTypes: [],
    maxCheckinWaitThreshold: 0,
    maxOnlineCheckin: 0,
    redirectUrl: "",
    orderTypes: [],
    defaultPickUpETA: 0,
    paymentProvider: null,
    deliveryKMRadius: null,
    locationDeliveryProviders: null,
    // Scheduled delivery
    scheduledDelivery: 0,
    scheduledDeliveryCutOffTime: "",
    scheduledDeliveryDurationInDays: "",
    locationSlug: "",
    disabledServices: null,
    cancellationPolicy: null,
    deliverySettings: null,
    platformConfiguration: null,
    aboutUs: null,
    merchantSlug: "",
    pickupCutoff: null,
  },
  masterRestaurantHomePageDetailsloading: false,
  masterRestaurantHomePageDetailsSuccess: false,
  restaurantTableDetailsLoading: false,
  restaurantTableDetailsFailed: false,
  restaurantTableDetailsSuccess: false,
  restaurantTableDetails: [],

  categoryMTypes : [],
  subCategoryMTypes :[],

  cdGuestCount:0
};

export default function restaurantReducer(
  state: RestaurantState = initialRestaurantState,
  action: restaurantActions
) {
  return produce(state, (draft) => {
    switch (action.type) {
      case STORE_SELECTED_LOCATION:
        draft.currentLocation = action.payload;
        break;
      case CLEAR_SELECTED_LOCATION:
        draft.currentLocation = null;
        break;
      case RESTAURANTS_REQUEST:
        draft.currentLocationRestaurants = [];
        draft.currentLocationRestaurantsLoading = true;
        break;
      case RESTAURANTS_SUCCESS:
        draft.currentLocationRestaurants = action.payload;
        draft.currentLocationRestaurantsLoading = false;
        break;
      case RESTAURANTS_FAILURE:
        draft.currentLocationRestaurants = [];
        draft.currentLocationRestaurantsLoading = false;
        break;
      case SERVING_RESTAURANTS_SUCCESS:
        draft.currentServingLocations = action.payload;
        break;
      case RESTAURANT_DETAIL_LOCATION_ID_REQUEST:
        // draft.restaurantDetailLoading = true;
        draft.currentRestaurantDetail.id = action.payload.locationId;
        if (action.payload.merchantType) {
          draft.currentMerchantType = action.payload.merchantType;
          draft.restaurantMenuDetails = [];
        }
        break;
      case RESTAURANT_DETAIL_LOCATION_ID_SUCCESS:
        draft.restaurantDetailsSuccess = true;
        // draft.restaurantDetailLoading = false;

        draft.currentRestaurantDetail = action.payload;
        break;
      case RESTAURANT_DETAIL_LOCATION_ID_CLEAR:
        draft.restaurantDetailLoading = false;
        draft.restaurantDetailsSuccess = false;
        draft.currentRestaurantDetail = restaurantData;
        break;
      case RESTAURANT_DETAIL_REQUEST:
        draft.restaurantDetailLoading = true;
        draft.currentRestaurantSlug = action.payload.slug;
        if (action.payload.merchantType) {
          draft.currentMerchantType = action.payload.merchantType;
          draft.restaurantMenuDetails = [];
        }
        draft.currentRestaurantDetail.id = "";
        break;
      case RESTAURANT_DETAIL_SUCCESS:
        draft.restaurantDetailLoading = false;
        draft.currentRestaurantDetail = action.payload;
        break;
      case RESTAURANT_DETAIL_FAILURE:
        draft.restaurantDetailLoading = false;
        draft.restaurantDetailFailMessage = action.payload.message;
        break;
      // Restaurant Menu Details
      case REFRESH_MENU_DETAIL:
        draft.refreshMenuDetails = !draft.refreshMenuDetails;
        break;
      case RESTAURANT_MENU_DETAIL_REQUEST:
        draft.restaurantCurrentMenuType = action.payload.type;
        draft.restaurantMenuDetailLoading = true;
        if (action.payload.type != draft.currentMenuType) {
          draft.restaurantMenuDetails = [];
        }
        draft.currentMenuType = action.payload.type;
        draft.categoryMTypes = [];
        draft.subCategoryMTypes = []; 
        break;
      case RESTAURANT_MENU_DETAIL_SUCCESS:
        draft.restaurantMenuDetailLoading = false;
        draft.restaurantMenuDetails = action.payload.menu;
        draft.restaurantOrderTypeId = action.payload.orderTypeId;
        draft.restaurantMenuCategories = action.payload.availableCategory;
        draft.restaurantSectionTypes = action.payload.sectionTypes;
        draft.restaurantOrderTax = action.payload.orderTax;
        draft.categoryMTypes = action.payload.categoryTypes;
        draft.subCategoryMTypes = action.payload.subCategoryTypes; 
        break;
        case RESTAURANT_ORDER_TYPE_SWITCH_SUCCESS:          
          draft.restaurantMenuDetailLoading = false;
          draft.restaurantMenuDetails = action.payload.typeName;
          draft.restaurantOrderTypeId = action.payload.id;
          // draft.restaurantMenuCategories = action.payload.availableCategory;
          // draft.restaurantSectionTypes = action.payload.sectionTypes;
          // draft.restaurantOrderTax = action.payload.orderTax;
          break;
      case CLEAR_RESTAURANT_DETAIL:
        draft.restaurantMenuDetailLoading = false;
        draft.restaurantMenuDetails = [];
        draft.restaurantOrderTypeId = "";
        draft.restaurantMenuCategories = [];
        draft.restaurantSectionTypes = [];
        draft.restaurantOrderTax = [];
        break;

      case RESTAURANT_MENU_DETAIL_FAILURE:
        draft.restaurantMenuDetailLoading = false;
        draft.restaurantMenuDetailFailMessage = action.payload.message;
        break;
      // Get Feedback Details
      case FEEDBACK_DETAIL_REQUEST:
        draft.feedbackDetailLoading = true;
        draft.feedbackSubmitted = false;
        break;
      case FEEDBACK_DETAIL_SUCCESS:
        draft.feedbackDetailLoading = false;
        draft.FeedbackQuestions = action.payload;
        break;
      case FEEDBACK_DETAIL_FAILURE:
        draft.feedbackDetailLoading = false;
        draft.feedbackDetailFailMessage = action.payload;
        break;

      case CLEAR_FEEDBACK_DATA:
        draft.feedbackDetailLoading = false;
        draft.feedbackSubmitted = false;
        draft.submitFeedbackDetailLoading = false;
        draft.submitFeedbackDetailFailMessage = "";
        break;
      //submitFeedback
      case SUBMIT_FEEDBACK_DETAIL_REQUEST:
        draft.submitFeedbackDetailLoading = true;
        draft.feedbackSubmitted = false;
        break;
      case SUBMIT_FEEDBACK_DETAIL_SUCCESS:
        draft.submitFeedbackDetailLoading = false;
        draft.feedbackSubmitted = true;
        break;
      case SUBMIT_FEEDBACK_DETAIL_FAILURE:
        draft.submitFeedbackDetailLoading = false;
        draft.submitFeedbackDetailFailMessage = action.payload;
        draft.feedbackSubmitted = false;
        break;
      //Active Order Restaurants
      case ACTIVE_ORDER_RESTAURANT_REQUEST:
        draft.activeOrderRestaurantLoading = true;
        draft.activeOrderRestaurantDetails = null;
        break;
      case ACTIVE_ORDER_RESTAURANT_SUCCESS:
        draft.activeOrderRestaurantDetails = action.payload;
        draft.activeOrderRestaurantLoading = false;
        break;
      case ACTIVE_ORDER_RESTAURANT_CLEAR:
        draft.activeOrderRestaurantDetails = null;
        draft.activeOrderRestaurantLoading = false;
        break;

      // Get Master restaurant home page getails
      case GET_MASTER_HOME_PAGE_DETAILS:
        draft.masterRestaurantHomePageDetailsloading = true;
        draft.masterRestaurantHomePageDetailsSuccess = false;
        break;
      case GET_MASTER_HOME_PAGE_DETAILS_SUCCESS:
        draft.masterRestaurantHomePageDetails = action.payload;
        draft.masterRestaurantHomePageDetailsloading = false;
        draft.masterRestaurantHomePageDetailsSuccess = true;
        break;
      case GET_MASTER_HOME_PAGE_DETAILS_ERROR:
        draft.masterRestaurantHomePageDetailsloading = false;
        draft.masterRestaurantHomePageDetailsSuccess = false;
        break;

      // Get Restaurant Table Details
      case GET_RESTAURANT_TABLE_DETAILS:
        draft.restaurantTableDetailsLoading = true;
        draft.restaurantTableDetailsFailed = false;
        draft.restaurantTableDetailsSuccess = false;
        draft.restaurantTableDetails = [];
        break;
      case GET_RESTAURANT_TABLE_DETAILS_SUCCESS:
        draft.restaurantTableDetailsLoading = false;
        draft.restaurantTableDetailsFailed = false;
        draft.restaurantTableDetailsSuccess = true;
        draft.restaurantTableDetails = action.payload;
        break;
      case GET_RESTAURANT_TABLE_DETAILS_FAILED:
        draft.restaurantTableDetailsLoading = false;
        draft.restaurantTableDetailsFailed = true;
        draft.restaurantTableDetailsSuccess = false;
        draft.restaurantTableDetails = [];
        break;
      case UPDATE_CD_GUEST_COUNT:  
        draft.cdGuestCount = action.payload;
        break;
      default:
        break;
    }
  });
}
