import React, {
  createContext,
  ReactNode,
  useState,
  useEffect,
  ReactElement,
  useContext,
} from "react";
import firebase from "firebase/app";
import "firebase/analytics";
import { firebaseAppContext } from "../firebase-app-service";
import { AuthContext } from "common/services/auth-service";

export const firebaseAnalyticsContext = createContext<firebase.analytics.Analytics | null>(
  null
);

interface FirebaseAnalyticsProviderProps {
  children: ReactNode;
  fallback?: ReactElement;
}

export default function FirebaseAnalyticsProvider({
  children,
  fallback,
}: FirebaseAnalyticsProviderProps) {
  const firebaseApp = useContext(firebaseAppContext);
  const AppAuth = useContext(AuthContext);

  const [
    firebaseAnalytics,
    setFirebaseAnalytics,
  ] = useState<firebase.analytics.Analytics | null>(null);

  useEffect(() => {
    if (firebaseAnalytics) {
      if (
        (AppAuth?.userState == "signedIn" ||
          AppAuth?.userState == "guestSignedIn") &&
        AppAuth.userAuth
      ) {
        // console.log("Firebase UserID Updated");
        firebaseAnalytics.setUserId(AppAuth.userAuth?.id);
      }
    }
  }, [AppAuth?.userState, firebaseAnalytics]);

  useEffect(() => {
    if (firebaseApp) {
      console.log("Initializing Firebase Analytics");
      setFirebaseAnalytics(firebaseApp.analytics());
    }
  }, []);

  return (
    <firebaseAnalyticsContext.Provider value={firebaseAnalytics}>
      {!firebaseAnalytics && fallback}
      {firebaseAnalytics && children}
    </firebaseAnalyticsContext.Provider>
  );
}
