import API from "common/api";
import {
  RestaurantMenuRequest,
  RestaurantsRequestParams,
  SubmitFeedbackDetail,
} from "./restaurantModels";
import { encryptJson } from "common/utils/react-ec-utils";

// Get Restaurants
export function getRestaurants(locationParams: RestaurantsRequestParams) {
  return API({
    method: "get",
    url: "/customer/findMerchant",
    params: locationParams,
  });
}

// Get Restaurant Details
export function getRestaurantDetails(slugId: string) {
  return API({
    method: "get",
    url: "/merchants/info/" + slugId,
  });
}

//  Get Restaurant details using location Id
export function getRestaurantDetailsbyLocationId(locationId: string) {
  return API({
    method: "get",
    url: `/merchants/location/${locationId}/details`,
  });
}

export function getRestaurantMenuDetails(details: RestaurantMenuRequest) {
  // console.log(details);

  return API({
    method: "get",
    url: "/merchants/menu",
    params: details,
  });
}

export function getFeedbackDetails() {
  return API({
    method: "get",
    url: "/customer/feedback",
  });
}

export function submitFeedback(SubmitFeedbackDetails: SubmitFeedbackDetail) {
  return API({
    method: "post",
    url: "/customer/feedback",
    data: SubmitFeedbackDetails,
  });
}

export function getmasterRestaurantHomePageDetails(slug: string) {
  return API({
    method: "get",
    url: `/merchants/details?slug=${slug}`,
  });
}

export function getRestaurantTableDetails(locationId: string) {
  return API({
    method: "get",
    url: `/merchants/locations/${locationId}/reservations/table-details`,
  });
}

export function getSearchResult(
  lat: string,
  lng: string,
  keyword: string,
  page: number
) {
  return API({
    method: "get",
    url: `/search?text=${keyword}&page=${page}&size=25&latitude=${lat}&longitude=${lng}`,
  });
}

export function getExactSearchResult(
  lat: string,
  lng: string,
  keyword: string
) {
  return API({
    method: "get",
    url: `/search?text=${keyword}&isExactMatch=1&latitude=${lat}&longitude=${lng}`,
  });
}
