export const APP_NAME = "CUSTOMER_APP";
export const CURRENT_CHECKIN_RESERVATION_ID = "CURRENT_CHECKIN_RESERVATION_ID";
export const CURRENT_ORDER_ID = "CURRENT_ORDER_ID";
export const USER_ID = "USER_ID";
export const ACTIVE_ORDERS = "ACTIVE_ORDERS";
export const EXPIRE_TIME = "EXPIRE_TIME";
export const ORDER_EXPIRE_TIME = "ORDER_EXPIRE_TIME";
export const GUEST_USER_NAME = "GUEST_USER_NAME";
export const USER_NAME = "USER_NAME";
export const GUEST_MOBILE_NUMBER = "GUEST_MOBILE_NUMBER";
export const GUEST_EMAIL = "GUEST_EMAIL";
export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const RESTAURANT_DETAIL = "RESTAURANT_DETAIL"; // Slug and LocationId
export const SELECTED_LOCATION = "SELECTED_LOCATION";
export const USER_ADDRESS = "USER_ADDRESS";
export const MAGIL_LOCATION = "1111111111111111";
export const GUEST_CART = "GUEST_CART";
export const CHECKIN_TABLE_ID = "CHECKIN_TABLE_ID";
export const STORAGE_BUCKET_URL =
  "https://storage.googleapis.com/magilhub-media/";
export const MOBILE_NUMBER = "MOBILE_NUMBER";
export const ORDER_STATUS_CODES = {
  ORDER_CREATED: "6",
  ORDER_MODIFIED: "7",
  ORDER_CANCELLED_CUSTOMER: "8",
  ORDER_CANCELLED_MERCHANT: "9",
  MERCHANT_ACCEPTED: "11",
  ORDER_BEING_PREPARED: "12",
  ORDER_READY: "13",
  ORDER_OUT_FOR_DELIVERY: "14",
  ORDER_DELIVERED: "15",
  ORDER_PICKED_UP: "16",
  ORDER_COMPLETE: "17",
  PAYMENT_INITIATED: "18",
  PAYMENT_SUCCESS: "19",
  PAYMENT_FAILURE_DECLINED: "20",
  PAYMENT_FAILURE_GATEWAY_ERROR: "21",
  PAYMENT_FAILURE_MAGIL_APP_ERROR: "22",
  PAYMENT_FAILURE_SIGN_MISMATCH: "23",
  ORDER_KOT_READY: "43",
  ITEM_IN_PREPARATION: "41",
  ITEM_READY: "42",
  PAYMENT_AT_LOCATION_INITIATED: "25",
  VALET_REACHED_DESTINATION: "50",
  ORDER_SERVED: "59",
  CONTACTLESS_ORDER_SERVED: "60",
};

export const WeekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const merchantTypes = ["restaurant"];

export const thirdPartyDeliveryProviders = {
  DUNZO: "DUNZO",
  SHADOWFAX: "SHADOWFAX",
  SIMPERY: "SIMPERY",
  DOORDASH: "DOORDASH",
};

export const DINE_IN_NOTIFICATION_REQUEST = "DINE_IN_REQUEST";
